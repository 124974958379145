import React, { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));
async function setMapDetails(token) {
  // console.log("Inside Set MapDetails")
  const result = await fetch(process.env.REACT_APP_GET_ORGIDMAP_URL + localStorage.getItem("auth_token"));
  // console.log(result, "result")
  const MapDetails = await result.json();
  // console.log(MapDetails, "MapDetails")
  if (MapDetails[0]) {
    localStorage.setItem("MAP_DETAILS", JSON.stringify(MapDetails));
    try {
      let orgDtls = await (await fetch(process.env.REACT_APP_GET_ORGDTLS_URL + MapDetails[0].Organization_ID)).json();
      localStorage.setItem(
        "ORGDTLS",
        JSON.stringify({
          telnyx_from: orgDtls.telnyx_from
        })
      );
    } catch (err) {
      console.error(err);
    }
  } else {
    const tempMap = [{ Organization_ID: "1" }];
    // console.log(JSON.stringify(tempMap))
    localStorage.setItem("MAP_DETAILS", JSON.stringify(tempMap));
  }
  if (MapDetails[0]) {
    // console.log("map details 0");
    const response = await fetch(process.env.REACT_APP_GET_USER_DTLS_URL + MapDetails[0]["User_ID"]);
    const userDtls = await response.json();
    localStorage.setItem("USER_DETAILS", JSON.stringify(userDtls));
  } else if (localStorage.getItem("auth_token")) {
    // console.log("map details 1");
    const response = await fetch(process.env.REACT_APP_GET_USER_DTLS_URL + localStorage.getItem("auth_token"));
    const userDtls = await response.json();
    localStorage.setItem("USER_DETAILS", JSON.stringify(userDtls));
  }
}
const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  setMapDetails(token).catch(console.error);
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig = currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
